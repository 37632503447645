import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService, private router: Router) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const session = this.sessionService.getSession();
    let cleanedParams = new HttpParams();

    req.params.keys().forEach((x) => {
      if (req.params.get(x) != undefined)
        cleanedParams = cleanedParams.append(x, req.params.get(x));
    });

    const excludedUrl = ['/v1/auth/signup/', '/v1/auth/password/reset/'];

    if (excludedUrl.some((x) => req.url.includes(x))) {
      return next.handle(req);
    }

    if (session) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${session.token}`,
        },
        params: cleanedParams,
      });
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.router.navigate(['/not-found']);
        } else if (err.status === 401) {
          this.sessionService.removeSession();
          this.router.navigate(['/login']);
        }

        return throwError(err);
      })
    );
  }

  cleanBodyOfNullValues(body) {
    let cleandedBody = {};
    if (body !== null) {
      for (let key in body) {
        if (body[key] !== null) {
          cleandedBody = {
            ...cleandedBody,
            [key]: body[key],
          };
        }
      }
      return cleandedBody;
    }
    return null;
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'register/:token',
    loadChildren: () =>
      import('./pages/register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'register_collaborator/:token',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'collaborator/register/:token',
    loadChildren: () =>
      import('./pages/register-collaborator/register-collaborator.module').then(
        (m) => m.RegisterCollaboratorModule
      ),
  },
  {
    path: 'password/reset/:id/:token',
    loadChildren: () =>
      import('./pages/auth/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'collaborator/password/reset/:id/:token',
    loadChildren: () =>
      import('./pages/auth/reset-regular-password/reset-regular-password.module').then(
        (m) => m.ResetRegularPasswordModule
      ),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('../app/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('../app/pages/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: 'access-denied',
    loadChildren: () =>
      import('../app/pages/access-denied/access-denied.module').then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: 'ranking',
    loadChildren: () =>
      import('../app/pages/ranking/ranking.module').then(
        (m) => m.RankingModule
      ),
  },
  {
    path: 'tickets',
    loadChildren: () =>
      import('../app/modules/fourteenth/tickets/tickets.module').then(
        (m) => m.TicketsModule
      ),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

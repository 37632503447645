import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnInit, NgZone } from '@angular/core';

import { PushNotificationService } from './push-notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'vivajourney';

  private destroy$ = new Subject();

  constructor(
    private notificationService: PushNotificationService,
    private router: Router,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.notificationService
      .receiveMessage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((notification) => {
        const _notification = new Notification(notification.data.title, {
          body: notification.data.body,
        });
        _notification.onclick = (event) => {
          event.preventDefault();
          this.zone.run(() => {
            this.router.navigate(['main', 'viva-plus'], {
              queryParams: {
                mode: 'DAY',
              },
            });
          });
        };
        setTimeout(_notification.close.bind(_notification), 6000);
      });
  }
}

import { Injectable } from '@angular/core';
import { Ng2IzitoastService } from 'ng2-izitoast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastOptions: IToastOptions;

  constructor(private toast: Ng2IzitoastService) {
    this.toastOptions = {
      message: '',
      position: 'topRight',
      timeout: 3000
    };
  }

  public success(message: string) {
    this.toast.success({ ...this.toastOptions, message });
  }

  public warning(message: string) {
    this.toast.warning({ ...this.toastOptions, message });
  }

  public info(message: string) {
    this.toast.info({ ...this.toastOptions, message });
  }

  public error(message: string) {
    this.toast.error({ ...this.toastOptions, message });
  }
}

interface IToastOptions {
  message: string;
  position: string;
  timeout: number;
}

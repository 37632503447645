import 'firebase/messaging';

import firebase from 'firebase/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';

import { PushNotification } from './modules/viva-plus/interfaces/appointmentNotification';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  public messagingFirebase: firebase.messaging.Messaging;

  private _onMessaging = new BehaviorSubject<boolean>(false);

  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
    this.messagingFirebase = firebase.messaging();
  }

  public onMessaging$() {
    return this._onMessaging.asObservable();
  }

  public requestPushNotification() {
    return new Promise(async (resolve, reject) => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const token = await this.messagingFirebase.getToken();
          resolve(token);
        } else {
          reject('No se otorgaron los permisos para notificación push');
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  private messagingObservable = new Observable<PushNotification>((observe) => {
    this.messagingFirebase.onMessage((payload) => {
      observe.next(payload);
      this._onMessaging.next(true);
    });
  });

  public receiveMessage() {
    return this.messagingObservable;
  }
}

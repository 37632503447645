import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Paths } from '../api/paths';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private router: Router, private http: HttpClient) {}

  public saveSession(token): void {
    localStorage.setItem('journeysession', JSON.stringify(token));
  }

  public getSession(): IToken {
    return JSON.parse(localStorage.getItem('journeysession'));
  }

  public removeSession() {
    localStorage.removeItem('journeysession');
    this.router.navigate(['/login']);
  }

  public saveRole(role) {
    localStorage.setItem('role', role);
  }

  public getRole(): number {
    return JSON.parse(localStorage.getItem('role'));
  }

  public saveAreaId(areaId) {
    localStorage.setItem('reviewing_area_id', areaId);
  }

  public getAreaId(): number {
    return JSON.parse(localStorage.getItem('reviewing_area_id'));
  }

  public deleteAreaId() {
    localStorage.removeItem('reviewing_area_id');
  }

  public saveBusinessUnitId(businessUnitId) {
    localStorage.setItem('jbui', businessUnitId);
  }

  public getBusinessUnitId(): number {
    return JSON.parse(localStorage.getItem('jbui'));
  }

  public saveUserId(userId) {
    localStorage.setItem('juser_id', userId);
  }

  public getUserId(): number {
    return JSON.parse(localStorage.getItem('juser_id'));
  }

  public setFirebaseToke(userId, firebase_token) {
    return this.http.put(`${environment.api.url}${Paths.users}${userId}`, {
      firebase_token,
    });
  }
}

export interface IToken {
  token: string;
}
